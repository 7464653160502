<template>
  <div>
    <div class="mb-2">
      <router-link :to="{ name: 'NicheAnalysis' }" class="back">
        <i class="pi pi-arrow-left" style="font-size: 0.8rem"></i>
        Niche Analysis
      </router-link>
    </div>

    <TabView>
      <TabPanel header="Keyword Trends">
        <KeywordTrends :id="id" />
      </TabPanel>
      <TabPanel header="Roots">
        <Roots :id="id" />
      </TabPanel>
      <TabPanel header="Competitors">
        <p>Coming soon...</p>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import KeywordTrends from "@/components/NicheAnalysis/KeywordTrends";
import Roots from "@/components/NicheAnalysis/Roots";

export default defineComponent({
  components: {
    TabView,
    TabPanel,

    KeywordTrends,
    Roots,
  },

  props: ["id"],
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.condition-title {
  font-weight: 500;
}

.condition-inputnumber {
  ::v-deep .p-inputnumber-input {
    width: 100px;
    margin-right: 0.5rem;
  }
}
</style>
