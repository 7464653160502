<template>
  <div>
    <Card class="main-content">
      <template #content>
        <div class="flex align-content-end">
          <h3>Keyword Trends</h3>
          <div>{{ dataSource?.length }} Ranked Keywords</div>
        </div>

        <!-- Filter -->
        <BlockUI :blocked="blocked">
          <div>
            <div class="mb-2">
              <div class="flex flex-column">
                <div class="condition-title">Roots(first 30 bt BSV)</div>
                <div class="p-fluid">
                  <MultiSelect
                    v-model="filterState.roots"
                    :options="allRoots"
                    optionLabel="label"
                    optionValue="value"
                    :filter="true"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="flex flex-row mb-2">
                <div class="flex flex-column">
                  <div class="condition-title">Word Count</div>
                  <div class="flex flex-row">
                    <InputNumber
                      v-model="filterState.word_count.gte"
                      mode="decimal"
                      :step="1"
                      placeholder="Min"
                      class="condition-inputnumber"
                    />
                    <InputNumber
                      v-model="filterState.word_count.lte"
                      mode="decimal"
                      :step="1"
                      placeholder="Max"
                      class="condition-inputnumber"
                    />
                  </div>
                </div>

                <div class="flex flex-column">
                  <div class="condition-title">Quick Filter</div>
                  <div class="flex flex-row">
                    <Dropdown
                      v-model="selectdQuickFilter"
                      :options="quickFilters"
                      optionLabel="name"
                      optionValue="options"
                      placeholder="Select a filter"
                      :showClear="true"
                      @change="updateFilters"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-row mb-2">
                <div class="flex flex-column">
                  <div class="condition-title">SV</div>
                  <div class="flex flex-row">
                    <InputNumber
                      v-model="filterState.sv.gte"
                      mode="decimal"
                      :step="1"
                      placeholder="Min"
                      class="condition-inputnumber"
                    />
                    <InputNumber
                      v-model="filterState.sv.lte"
                      mode="decimal"
                      :step="1"
                      placeholder="Max"
                      class="condition-inputnumber"
                    />
                  </div>
                </div>

                <div class="flex flex-column">
                  <div class="condition-title">Ranked Products</div>
                  <div class="flex flex-row">
                    <InputNumber
                      v-model="filterState.ranked_products.gte"
                      mode="decimal"
                      :step="1"
                      placeholder="Min"
                      class="condition-inputnumber"
                    />
                    <InputNumber
                      v-model="filterState.ranked_products.lte"
                      mode="decimal"
                      :step="1"
                      placeholder="Max"
                      class="condition-inputnumber"
                    />
                  </div>
                </div>

                <div class="flex flex-column">
                  <div class="condition-title">CPR</div>
                  <div class="flex flex-row">
                    <InputNumber
                      v-model="filterState.cpr.gte"
                      mode="decimal"
                      :step="1"
                      placeholder="Min"
                      class="condition-inputnumber"
                    />
                    <InputNumber
                      v-model="filterState.cpr.lte"
                      mode="decimal"
                      :step="1"
                      placeholder="Max"
                      class="condition-inputnumber"
                    />
                  </div>
                </div>
              </div>

              <div class="pl-1">
                <p class="text-pink-700 font-semibold">
                  Note: The calculation rules for SVR have changed after
                  2023-05-14.
                </p>
              </div>
              <div
                class="flex justify-content-end align-content-center mb-2"
                style="min-height: 40px"
              >
                <div class="mr-2">
                  <Button class="p-button-link" @click="clearFilters">
                    Clear Filters
                  </Button>
                </div>
                <div class="mr-2">
                  <Button
                    class="p-button-link"
                    @click="toggleSavedQuickFilterDialog"
                  >
                    Save as Quick Filters
                  </Button>
                </div>
                <div>
                  <Button class="handleSearch" @click="handleFilters">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </BlockUI>

        <div style="height: 1em">
          <template v-if="loadingKeywordsTrends">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
          </template>
        </div>

        <DataTable
          :value="dataSource"
          v-model:filters="filters"
          dataKey="id"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="50"
          @page="handlePage"
          @sort="handleSort"
          @value-change="handleValueChange"
          :loading="loadingKeywords"
        >
          <template #loading>Loading records, please wait...</template>
          <Column field="phrase" header="Keyword" style="width: 100px" sortable>
            <template #body="{ field, data }">
              {{ data[field] }}
            </template>
          </Column>

          <Column
            field="search_volume"
            header="SV"
            style="width: 100px"
            sortable
          >
            <template #body="{ field, data }">
              {{ formatNumberWithoutZero(data[field]) }}
            </template>
          </Column>

          <Column field="svr" header="SVR" style="width: 100px" sortable>
            <template #body="{ field, data }">
              {{ data[field] }}
            </template>
          </Column>

          <Column field="cpr" header="CPR" style="width: 100px" sortable>
            <template #body="{ field, data }">
              {{ formatNumberWithoutZero(data[field]) }}
            </template>
          </Column>

          <Column
            header="Trend Graph"
            style="width: 100px"
            columnKey="trend_graph"
          >
            <template #body="{ data }">
              <div style="display: flex">
                <Chart
                  type="line"
                  :data="renderTrendGraph(data.chart_data, 'Sfr')"
                  :options="SVRTrendGraphOptions"
                />
                <Chart
                  type="line"
                  :data="
                    renderTrendGraph(data.chart_data, 'TotalConversionShare')
                  "
                  :options="trendGraphOptions('Top3 Conv. Share')"
                />
                <Chart
                  type="line"
                  :data="renderTrendGraph(data.chart_data, 'TotalClickShare')"
                  :options="trendGraphOptions('Top3 Click Share')"
                />
              </div>
            </template>
          </Column>

          <Column header="Ranked Products" columnKey="rank">
            <template #body="{ data }">
              <div class="flex flex-row">
                <div class="flex align-items-center justify-content-center">
                  <Chip :label="data.relevancy.toString()" />
                </div>
                <div
                  class="flex align-items-center justify-content-center"
                  style="overflow: auto"
                >
                  <div
                    v-for="ranked_product of data.ranked_products"
                    :key="ranked_product.asin"
                    style="margin-left: 5px; font-size: smaller; width: 120px"
                  >
                    <div class="flex flex-column">
                      <div
                        class="flex align-items-center justify-content-center"
                      >
                        <img
                          :src="listingImage(ranked_product.asin)"
                          style="height: 50px"
                        />
                      </div>
                      <a
                        :href="listingUrl(ranked_product.asin)"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {{ `#${ranked_product.rank} ${ranked_product.asin}` }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <!-- Save Quick Filter Dialog -->
    <Dialog v-model:visible="displaySavedQuickFilterDialog">
      <template #header>
        <h3>Save Quick Filter</h3>
      </template>

      <InputText type="text" v-model="quickFilterName" style="width: 100%" />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="toggleSavedQuickFilterDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          autofocus
          @click="saveAsQuickFilter"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Chart from "primevue/chart";
import Chip from "primevue/chip";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import ProgressBar from "primevue/progressbar";

import { FilterOperator, FilterMatchMode, FilterService } from "primevue/api";

import NicheAnalysisService from "@/services/niche_analysis_service";

import { formatNumberWithoutZero } from "@/utils/formatter";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Chart,
    Chip,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    InputNumber,
    InputText,
    MultiSelect,
    ProgressBar,
  },

  props: ["id"],
  setup(props) {
    onMounted(async () => {
      loadKeyowrds();
      loadQuickFilters();
    });

    const loadKeyowrds = () => {
      blocked.value = true;
      loadingKeywords.value = true;

      let params = {};
      NicheAnalysisService.listKeywords(props.id, params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Keywords not found!",
              life: 3000,
            });
          } else {
            dataSource.value = data.keywords;
            loadRootsFirst30();
            loadKeywordsTrends(dataSource.value);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
          loadingKeywords.value = false;
        });
    };

    const loadQuickFilters = async () => {
      NicheAnalysisService.listQuickFilters(props.id)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Quick Filters not found!",
              life: 3000,
            });
          } else {
            quickFilters.value = data.quick_filters;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const loadRootsFirst30 = async () => {
      let params = { limit: 30 };
      NicheAnalysisService.listRoots(props.id, params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Roots not found!",
              life: 3000,
            });
          } else {
            let options = [];
            for (let record of data.roots) {
              let formattedBsv = formatNumberWithoutZero(record.bsv);
              let option = {
                label: `${record.root} - ${formattedBsv}`,
                value: record.root,
                bsv: record.bsv,
              };
              options.push(option);
            }
            allRoots.value = options;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const blocked = ref(false);
    const loadingKeywords = ref(false);
    const loadingKeywordsTrends = ref(false);
    const toast = useToast();

    const dataSource = ref();
    const filterState = reactive({
      word_count: {
        gte: null,
        lte: null,
      },
      sv: {
        gte: null,
        lte: null,
      },
      ranked_products: {
        gte: null,
        lte: null,
      },
      cpr: {
        gte: null,
        lte: null,
      },
      roots: [],
    });
    const allRoots = ref([]);
    const rootsFilterFn = (value, filters) => {
      let keys = Object.keys(value);
      for (let key of keys) {
        if (filters.includes(key)) {
          return true;
        }
      }
      return false;
    };
    const FILTER_MATCH_MODE_ROOTS = "rootsfilter";
    FilterService.register(FILTER_MATCH_MODE_ROOTS, rootsFilterFn);

    const filters = reactive({});

    const clearFilters = () => {
      filterState.roots = [];
      filterState.word_count.gte = null;
      filterState.word_count.lte = null;
      filterState.sv.gte = null;
      filterState.sv.lte = null;
      filterState.ranked_products.gte = null;
      filterState.ranked_products.lte = null;
      filterState.cpr.gte = null;
      filterState.cpr.lte = null;
    };

    const isNonFilter = () => {
      if (filterState.roots.length > 0) {
        return false;
      }

      if (filterState.word_count.gte || filterState.word_count.lte) {
        return false;
      }

      if (filterState.sv.gte || filterState.sv.lte) {
        return false;
      }

      if (filterState.ranked_products.gte || filterState.ranked_products.lte) {
        return false;
      }

      if (filterState.cpr.gte || filterState.cpr.lte) {
        return false;
      }

      return true;
    };

    const selectdQuickFilter = ref();
    const quickFilters = ref();
    const quickFilterName = ref();
    const displaySavedQuickFilterDialog = ref(false);

    const toggleSavedQuickFilterDialog = () => {
      if (!displaySavedQuickFilterDialog.value && isNonFilter()) {
        toast.add({
          severity: "warn",
          summary: "No Filter!",
          life: 3000,
        });

        return;
      }

      displaySavedQuickFilterDialog.value =
        !displaySavedQuickFilterDialog.value;
    };

    const saveAsQuickFilter = () => {
      if (!quickFilterName.value || quickFilterName.value.trim() === "") {
        toast.add({
          severity: "error",
          summary: "Name can not be blank!",
          life: 3000,
        });
        return;
      }

      blocked.value = true;

      let payload = {
        name: quickFilterName.value,
        options: {
          roots: filterState.roots,
          word_count: filterState.word_count,
          sv: filterState.sv,
          ranked_products: filterState.ranked_products,
          cpr: filterState.cpr,
        },
      };
      NicheAnalysisService.saveQuickFilter(props.id, payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Can not save Quick Filter!",
              life: 3000,
            });
          } else {
            loadQuickFilters();
          }
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Can not save Quick Filter!",
            life: 3000,
          });
        })
        .finally(() => {
          blocked.value = false;
          toggleSavedQuickFilterDialog();
        });
    };

    const updateFilters = (event) => {
      let options = event.value;

      // clear filters
      if (!options) {
        return;
      }

      filterState.roots = options.roots;
      filterState.word_count = options.word_count;
      filterState.sv = options.sv;
      filterState.ranked_products = options.ranked_products;
      filterState.cpr = options.cpr;
    };

    const handleFilters = () => {
      if (filterState.roots.length === 0) {
        delete filters.roots;
      } else {
        filters.roots = {
          value: filterState.roots,
          matchMode: FILTER_MATCH_MODE_ROOTS,
        };
      }

      contiditionToFilters(
        "word_count",
        filterState.word_count.gte,
        filterState.word_count.lte
      );

      contiditionToFilters(
        "search_volume",
        filterState.sv.gte,
        filterState.sv.lte
      );

      contiditionToFilters(
        "relevancy",
        filterState.ranked_products.gte,
        filterState.ranked_products.lte
      );

      contiditionToFilters("cpr", filterState.cpr.gte, filterState.cpr.lte);
    };

    const contiditionToFilters = (filterColumn, gte, lte) => {
      if (gte && lte) {
        filters[filterColumn] = {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: gte,
              matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
            },
            {
              value: lte,
              matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
            },
          ],
        };
      } else if (gte) {
        filters[filterColumn] = {
          value: gte,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        };
      } else if (lte) {
        filters[filterColumn] = {
          value: lte,
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        };
      } else {
        delete filters[filterColumn];
      }
    };

    const listingImage = (asin) => {
      return `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`;
    };

    const listingUrl = (asin) => {
      return `https://www.amazon.com/dp/${asin}`;
    };

    let currentPage = 0;
    const handlePage = (event) => {
      currentPage = event.page || 0;
    };

    const handleSort = () => {
      currentPage = 0;
    };

    const handleValueChange = (value) => {
      loadKeywordsTrends(value);
    };

    const loadKeywordsTrends = async (value) => {
      loadingKeywordsTrends.value = true;

      const pageSize = 50;
      let start = 0 + currentPage * pageSize;
      let end = start + pageSize;

      let keywords = [];
      for (let i = start; i < end; i++) {
        if (!value[i]) {
          break;
        }

        keywords.push(value[i].phrase);
        value[i].svr = "loading";
        value[i].chart_data = [];
      }

      if (keywords.length === 0) {
        loadingKeywordsTrends.value = false;
        return;
      }

      let params = {
        keywords: keywords,
      };
      NicheAnalysisService.listKeywordsTrends(params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Keywords Trends not found!",
              life: 3000,
            });
          } else {
            if (data.total > 0) {
              for (let i = start; i < end; i++) {
                if (!value[i]) {
                  break;
                }

                let k = value[i].phrase;
                for (let j = 0; j < data.total; j++) {
                  if (k === data.result[j].keyword) {
                    value[i].svr = formatNumberWithoutZero(data.result[j].sfr);
                    value[i].chart_data = data.result[j].chart_data;
                    break;
                  }
                }

                if (value[i].svr === "loading") {
                  value[i].svr = "-";
                }
              }
            } else {
              for (let i = start; i < end; i++) {
                if (!value[i]) {
                  break;
                }

                value[i].svr = "-";
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Timeout or other error!",
            life: 3000,
          });
        })
        .finally(() => {
          loadingKeywordsTrends.value = false;
        });
    };

    // Trend Graph
    const SVRTrendGraphOptions = {
      plugins: {
        legend: {
          display: false, // hide the legend
        },
        title: {
          display: true,
          text: "SVR",
          position: "bottom",
        },
      },
      scales: {
        y: {
          reverse: true, // reverse y axis
        },
      },
    };

    const trendGraphOptions = (title) => {
      return {
        plugins: {
          legend: {
            display: false, // hide the legend
          },
          title: {
            display: true,
            text: title,
            position: "bottom",
          },
        },
      };
    };

    const renderTrendGraph = (chartData, field) => {
      let lineData = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: "#42A5F5",
          },
        ],
      };

      if (!chartData) {
        return lineData;
      }

      chartData.forEach((element) => {
        lineData.labels.push(element.PartitionDate);
        lineData.datasets[0].data.push(element[field]);
      });

      return lineData;
    };

    return {
      blocked,
      loadingKeywords,
      loadingKeywordsTrends,

      dataSource,
      filterState,

      allRoots,

      filters,
      clearFilters,
      saveAsQuickFilter,
      handleFilters,

      selectdQuickFilter,
      quickFilters,
      quickFilterName,
      displaySavedQuickFilterDialog,
      toggleSavedQuickFilterDialog,
      updateFilters,

      listingImage,
      listingUrl,

      handlePage,
      handleSort,
      handleValueChange,

      SVRTrendGraphOptions,
      trendGraphOptions,
      renderTrendGraph,

      formatNumberWithoutZero,
    };
  },
});
</script>

<style lang="scss" scoped>
.condition-title {
  font-weight: 500;
}

.condition-inputnumber {
  ::v-deep .p-inputnumber-input {
    width: 100px;
    margin-right: 0.5rem;
  }
}
</style>
