<template>
  <div>
    <Card class="main-content">
      <template #content>
        <div class="flex align-content-end">
          <h3>Roots</h3>
          <div>{{ dataSource?.length }}</div>
        </div>

        <DataTable
          :value="dataSource"
          dataKey="id"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="50"
          :loading="loadingRoots"
        >
          <template #loading>Loading records, please wait...</template>
          <Column field="root" header="Root" style="width: 100px" sortable>
            <template #body="{ field, data }">
              {{ data[field] }}
            </template>
          </Column>

          <Column
            field="frequency"
            header="Frequency"
            style="width: 100px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>

          <Column field="bsv" header="BSV" style="width: 100px" sortable>
            <template #body="{ field, data }">
              <div class="text-right">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";

import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import NicheAnalysisService from "@/services/niche_analysis_service";

import { formatNumberWithoutZero } from "@/utils/formatter";

export default defineComponent({
  components: {
    Card,
    Column,
    DataTable,
  },

  props: ["id"],
  setup(props) {
    onMounted(async () => {
      loadRoots();
    });

    const loadRoots = async () => {
      loadingRoots.value = true;

      let params = {};
      NicheAnalysisService.listRoots(props.id, params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Roots not found!",
              life: 3000,
            });
          } else {
            dataSource.value = data.roots;
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          loadingRoots.value = false;
        });
    };

    const loadingRoots = ref(false);
    const toast = useToast();

    const dataSource = ref();

    return {
      loadingRoots,

      dataSource,

      formatNumberWithoutZero,
    };
  },
});
</script>

<style scoped></style>
